import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {YardsaleEvent} from "../model/yardsaleEvent";
import apiRoot from "../api";
import {resetObjectValues} from "../util/resetObject";
import useJwt from "../util/useJwt";
import moment from 'moment'
import Datepicker from "tailwind-datepicker-react"

const Dashboard = () => {
    const [formVisible, setFormVisible] = useState(false);
    const toggleVisibility = () => {
        setFormVisible(!formVisible);
    };
    // // const [user] = useLocalState('', 'user')
    const {getJwt, updateJwt, clearJwt} = useJwt();
    let jwt = getJwt();
    const [events, setEvents] =
        useState<YardsaleEvent[]>([])
    const [yardsale, setYardsale] = useState({
        address: '',
        date: new Date(),
        description: '',
    });
    const handleAddress = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        // const name = '';
        // const value = '';
        setYardsale({
            ...yardsale,
            [name]: value
        });
    };
    const navigate = useNavigate()
    useEffect(() => {
        if (!jwt) navigate('/login')
        if (jwt) {
            fetch(`${apiRoot}/api/event`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                method: 'GET'
            })
                .then((response) => {
                    if (response.status === 200) return response.json()
                }).then((events: YardsaleEvent[]) => {
                events.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                setEvents(events)
            })
        }
    }, []);

    function isValidWesternDate(dateString: string) {
        // Check the format first (MM/DD/YYYY)
        const regex = /^(0[1-9]|1[012])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        if (!regex.test(dateString)) {
            return false;
        }

        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return date && date.getMonth() + 1 === month && date.getDate() === day;
    }

    const createEvent = (event: any) => {
        event.preventDefault();
        if (!yardsale.address) {
            alert('address is required')
            return
        }
        if (yardsale.date != null && !yardsale.date) {
            alert('date is required')
            return
        }
        // console.log(moment(yardsale.date).format('DD/MM/YYYY'))
        fetch(`${apiRoot}/api/event`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            method: 'POST',
            body: JSON.stringify({
                address: `${yardsale.address}`,
                description: yardsale.description,
                date: moment(yardsale.date).format('L')
            })
        }).then(response => {
            if (response.status === 200) return response.json();
            else throw new Error('address invalid');
        }).then((data: YardsaleEvent) => {
            if (data) {
                alert('event added')
                // console.log(data);
                if (events.length > 0) {
                    let newEvents = [...events, data];
                    newEvents.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
                    setEvents(newEvents);
                } else {
                    setEvents([data])
                }
            } else throw new Error('address invalid')
            setYardsale(resetObjectValues(yardsale, ''))
        }).catch(error => {
            alert(error)
            // navigate('/error')
        })


    }

    const changeEventStatus = (eventId: string) => {
        fetch(`${apiRoot}/api/event/active/${eventId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            method: 'POST',
            body: JSON.stringify({
                address: `${yardsale.address}`,
                description: yardsale.description,
                date: moment(yardsale.date).format('L')
            })
        }).then(resp => {
            if (resp.status === 200) return resp.json()
            else throw new Error(resp.statusText);
        }).then((data: YardsaleEvent) => {
            alert('update success')
            const updatedEvents = events.map(item => {
                if (item.id === data.id) {
                    // Return a new object with the updated name
                    // console.log(JSON.stringify(data))
                    // console.log(`event: ${data.id}, setActive:${data.active}`)
                    return {...item, isActive: data.active};
                }
                return item;
            });
            setEvents(updatedEvents)
        }).catch(error => {
            alert(error)
        })
    }

    const [dateShow, setDateShow] = useState(false)
    const handleChange = (selectedDate: Date) => {
        setYardsale({...yardsale, date: selectedDate})
    }
    const handleClose = (state: boolean) => {
        setDateShow(state)
    }

    const dateOptions = {
        maxDate: new Date("2030-01-01"),
        minDate: new Date(),
    }

    return (
        <>
            <button type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2  focus:outline-none "
                    onClick={() => {
                        toggleVisibility()
                    }}>
                Show Form
            </button>
            <form className={"max-w-lg mx-auto w-full p-2" + ((formVisible) ? " " : " hidden ")}>
                <div className="mb-2">
                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">
                        Address
                    </label>
                    <input type="text"
                           id="address" name="address"
                           onChange={handleAddress} defaultValue={yardsale.address}
                           aria-describedby="helper-text-explanation"
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                           placeholder="2585 N Tustin St, Orange, CA 92865"/>
                </div>
                <div className="mb-2 z-0">
                    <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">
                        Date
                    </label>
                    {/*<Datepicker value={{startDate: new Date(), endDate: addOneMonthToDate(new Date())}}*/}
                    {/*            onChange={(date) => {*/}
                    {/*                // if (!date) date = new Date()*/}
                    {/*                // setYardsale({...yardsale, date: date})*/}
                    {/*                console.log(date);*/}
                    {/*            }}/>*/}
                    <Datepicker options={dateOptions} onChange={handleChange} show={dateShow} setShow={handleClose}/>
                </div>
                <div className="mb-2">
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">
                        Description
                    </label>
                    <textarea id="description" name="description"
                              onChange={handleAddress} defaultValue={yardsale.description}
                              rows={4}
                              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="Tell us what you gonna sell..."
                    ></textarea>
                </div>
                <button type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        onClick={createEvent}>
                    Create New Yard Sale
                </button>
            </form>
            <div className="">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead
                            className="text-xs text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                #
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Attendee numbers
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Time
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Address
                            </th>

                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Active</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {events ? events.map(event => {
                            // console.log(event)
                            return (
                                <tr className="bg-white border-b hover:bg-gray-50" key={event.id}>
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                        <Link to={`/event/${event.id}`}> {event.id} </Link></th>
                                    <td className="px-6 py-4">{event.attendeeNumber}</td>
                                    <td className="px-6 py-4">{event.date}</td>
                                    <td className="px-6 py-4">{event.address}</td>
                                    <td className="px-6 py-4">
                                        {event.active ?
                                            (<button
                                                className="font-medium text-blue-600  hover:underline"
                                                onClick={
                                                    (e) => {
                                                        changeEventStatus(event.id)
                                                    }
                                                }>Disactive</button>) :
                                            (<button
                                                className="font-medium text-blue-600  hover:underline"
                                                onClick={
                                                    (e) => {
                                                        changeEventStatus(event.id)
                                                    }
                                                }>Active</button>)}
                                    </td>
                                </tr>
                            )
                        }) : (<></>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}

export default Dashboard;