import React from 'react';
import {faLinkedin} from "@fortawesome/free-brands-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
function Footer() {
    return (
        <footer className="bg-white rounded-lg shadow m-4">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-gray-500 sm:text-center">© 2023 YardSaleNearMe.net All Rights Reserved.</span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 sm:mt-0">
                    {/*<li>*/}
                    {/*    <a href="#" className="hover:underline me-4 md:me-6">About</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="#" className="hover:underline me-4 md:me-6">Privacy Policy</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href="https://www.linkedin.com/in/sylin83/" className="hover:underline me-4 md:me-6"><FontAwesomeIcon icon={faLinkedin} /></a>*/}
                    {/*</li>*/}
                    <li>
                        <a href="mailto:newianlin@gmail.com" className="hover:underline">Contact</a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer