import React from 'react';
import {Link} from 'react-router-dom';

const ErrorPage = () => {
    const styles = {
        container: {
            paddingTop: '100px',
            textAlign: 'center'
        },
        heading: {
            fontSize: '36px',
            color: 'red',
        },
        message: {
            fontSize: '20px',
        },
        link: {
            fontSize: '18px',
            textDecoration: 'underline',
            marginTop: '20px',
        },
    };
    return (
        <div style={{textAlign: 'center', paddingTop: '100px'}}>
            <h1 style={styles.heading}>Oops! An error occurred.</h1>
            <p style={styles.message}>We're sorry, but something went wrong.</p>
            <Link to="/" style={styles.link}>Go back to the homepage</Link>
        </div>
    );
}


export default ErrorPage;
