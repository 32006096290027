import {GoogleMap, InfoWindow, Marker, LoadScript} from "@react-google-maps/api";
import {useEffect, useState} from "react";
import {MarkerInfo} from "../model/markerInfo";
import useJwt from "../util/useJwt";
import {useNavigate} from "react-router-dom";
import apiRoot from "../api";
import googleAPI from "../googleApi";
import {YardsaleEvent} from "../model/yardsaleEvent";

const Map = (props: {
    center: {
        lat: number;
        lng: number;
    }
}) => {
    // let map: google.maps.Map;
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<MarkerInfo[]>([]);
    const [activeMarker, setActiveMarker] = useState<number | null>(null);
    const {getJwt, updateJwt, clearJwt} = useJwt();
    const jwt = getJwt();
    const navigate = useNavigate();
    // const [markers, setMarkers] = useState<MarkerInfo[]>([]);
    const handleActiveMarker = (marker: number) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };


    function replaceSpacesAndCommas(inputString: string): string {
        // Replace commas with '%2C'
        return inputString.replace(/, /g, '%2C').replace(/ /g, '+');
    }

    function attendEvent(eventId: string) {
        fetch(`${apiRoot}/api/event/attend/${eventId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            method: 'POST'
        }).then(response => {
            if (response.status === 200) return response.json();
        }).then(data => {
            let updatedMarkers = markers.map((event, index) => {
                if (event.id == eventId) {
                    return {...event, attend: !event.attend};
                }
                return event;
            })
            setMarkers(updatedMarkers)
        }).catch(error => {
            alert(error);
        })
    }

    const handleOnLoad = (mapInstance: google.maps.Map) => {
        // console.log(`map is loaded+${mapInstance}`)
        setMap(mapInstance)
        fetchEvents(props.center.lng, props.center.lat)
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then((data: YardsaleEvent[]) => {
                // console.log(JSON.stringify(data))
                if (data) {
                    const targetList = data.map((item) => ({
                        id: item.id,
                        title: item.address,
                        description: item.description,
                        position: {lat: item.latitude, lng: item.longitude},
                        test: item.test,
                        attend: item.attend,
                        address: item.address,
                        date: item.date
                    }));
                    setMarkers(targetList)
                }
            })
            .catch(error => {
                // navigate('/error')
                console.log(error);
            })
    };
    const handleMapMove = () => {
        // console.log(`center changed+ ${map}`)
        if (map) {
            fetchEvents(map.getCenter()!.lng(), map.getCenter()!.lat())
                .then(response => {
                    if (response.status === 200) return response.json();
                })
                .then((data: YardsaleEvent[]) => {
                    // console.log(JSON.stringify(data))
                    if (data) {
                        const targetList = data.map((item) => ({
                            id: item.id,
                            title: item.address,
                            description: item.description,
                            position: {lat: item.latitude, lng: item.longitude},
                            test: item.test,
                            attend: item.attend,
                            address: item.address,
                            date: item.date
                        }));
                        setMarkers(targetList)
                    }
                })
                .catch(error => {
                    // navigate('/error')
                    console.log(error);
                })
        }

    }

    useEffect(() => {
        fetchEvents(props.center.lng, props.center.lat)
            .then(response => {
                if (response.status === 200) return response.json();
            })
            .then((data: YardsaleEvent[]) => {
                if (data) {
                    const targetList = data.map((item) => ({
                        id: item.id,
                        title: item.address,
                        description: item.description,
                        position: {lat: item.latitude, lng: item.longitude},
                        test: item.test,
                        attend: item.attend,
                        address: item.address,
                        date: item.date
                    }));
                    setMarkers(targetList)
                }
            })
            .catch(error => {
                // navigate('/error')
                console.log(error);
            })
    }, []);

    const fetchEvents = (lng: number, lat: number) => {
        return fetch(`${apiRoot}/api/event/coord`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            method: 'POST',
            body: JSON.stringify({
                lng: lng,
                lat: lat
            })
        })
    }

    return (
        <>
            <div className="hidden sm:hidden md:block">
                <LoadScript googleMapsApiKey={googleAPI}>
                    <GoogleMap
                        onLoad={handleOnLoad}
                        onClick={() => setActiveMarker(null)}
                        mapContainerStyle={{width: "100%", height: "55vh"}}
                        zoom={11}
                        center={props.center}
                        onDragEnd={handleMapMove}
                    >
                        {markers.map((marker, index) => (
                            <Marker
                                key={index}
                                position={marker.position}
                                onClick={() => handleActiveMarker(index)}
                                icon={{
                                    url: (marker.test) ? "/test_pin.png" : "/real_pin.png", // URL to the custom marker icon
                                    scaledSize: new window.google.maps.Size(20, 20) // Size of the icon
                                }}
                            >
                                {activeMarker === index ? (
                                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                        <div
                                            className="flex flex-col items-center p-1 bg-white rounded-lg shadow text-center">
                                            <a className="hover:underline"
                                               href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" + replaceSpacesAndCommas(marker.title)}>
                                                <h5 className="mb-1 font-semibold tracking-tight text-gray-900">{marker.title}</h5>
                                            </a>
                                            <p className="font-normal text-gray-700 pb-1">
                                                Date: {marker.date}</p>
                                            <br/>
                                            <p className="font-normal text-gray-700 pb-1">
                                                What we have: {marker.description}</p>
                                            <br/>
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-5/12 bottom-0"
                                                onClick={() => {
                                                    if (!jwt) navigate('/login')
                                                    else attendEvent(marker.id);
                                                }}>
                                                {(marker.attend) ? ("Unattend") : ("Attend")}
                                            </button>
                                        </div>
                                    </InfoWindow>
                                ) : null}
                            </Marker>
                        ))}
                    </GoogleMap>

                </LoadScript>
            </div>
            <div className="flex-row block sm:block md:hidden">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead
                        className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Time
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Address
                        </th>

                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Active</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {markers ? markers.map(marker => {
                        // console.log(event)
                        return (
                            <tr className="bg-white border-b hover:bg-gray-50" key={marker.id}>
                                <td className="px-6 py-4">{marker.date}</td>
                                <td className="px-6 py-4">{marker.address}</td>
                                <td className="px-6 py-4">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded bottom-0"
                                        onClick={() => {
                                            if (!jwt) navigate('/login')
                                            else attendEvent(marker.id);
                                        }}>
                                        {(marker.attend) ? ("Unattend") : ("Attend")}
                                    </button>
                                </td>
                            </tr>
                        )
                    }) : (<></>)}
                    </tbody>
                </table>
            </div>
        </>

    )
}

export default Map