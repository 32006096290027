import React, {useEffect} from 'react';
import {useLocalState} from "../util/useLocalStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {UserDto} from "../model/userDto";
import useJwt from "../util/useJwt";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Header = () => {
    const navigate = useNavigate();
    const {getJwt, updateJwt, clearJwt} = useJwt();
    let jwt = getJwt()
    const handleLogout = () => {
        clearJwt()
        navigate('/login')
    };

    useEffect(() => {
        // console.log(jwt)
        // const handleJwtChange = () => {
        //     jwt = getJwt();
        // };
        // // console.log(`jwt: ${jwt}`)
        //
        // window.addEventListener('jwtChanged', handleJwtChange);
        //
        // return () => {
        //     window.removeEventListener('jwtChanged', handleJwtChange);
        // };
    }, []);
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (

        <>
            <nav className="bg-white rounded-lg shadow m-4">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                    <div
                        className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <a
                            className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black"
                            href="/"
                        >
                            Yard Sale Near Me
                        </a>
                        <button
                            className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <FontAwesomeIcon icon={faBars}/>
                        </button>
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center" +
                            (navbarOpen ? " flex" : " hidden")
                        }
                        id="example-navbar-danger"
                    >
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                                    href="/"
                                >
                                    <i className="fab fa-facebook-square text-lg leading-lg text-black opacity-75"></i><span
                                    className="ml-2">Home</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                                    href="/dashboard"
                                >
                                    <i className="fab fa-twitter text-lg leading-lg text-black opacity-75"></i><span
                                    className="ml-2">Dashboard</span>
                                </a>
                            </li>
                            {
                                (!jwt) ? (
                                    <>
                                        <li className="nav-item">
                                            <a
                                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                                                href="/login"
                                            >
                                                <i className="fab fa-twitter text-lg leading-lg text-black opacity-75"></i><span
                                                className="ml-2">Login</span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                                                href="/register"
                                            >
                                                <i className="fab fa-twitter text-lg leading-lg text-black opacity-75"></i><span
                                                className="ml-2">SignUp</span>
                                            </a>
                                        </li>

                                    </>
                                ) : (
                                    <>
                                        <li className="nav-item">
                                            <button
                                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75"
                                                onClick={() => {
                                                    handleLogout()
                                                }}>
                                                Sign Out
                                            </button>
                                        </li>
                                    </>
                                )
                            }

                        </ul>
                    </div>
                </div>
            </nav>
        </>

    );
}

export default Header;