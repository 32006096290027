import React, {useEffect, useState} from "react";
import {useLocalState} from "../util/useLocalStorage";
import {useNavigate} from "react-router-dom";
import apiRoot from "../api";
import {GoogleLogin} from "@react-oauth/google";
import {UserDto} from "../model/userDto";
import useJwt from "../util/useJwt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";

const Register = () => {
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const {getJwt, updateJwt, clearJwt} = useJwt();
    const jwt = getJwt()
    const navigate = useNavigate()
    useEffect(() => {
        if (jwt) {
            navigate('/dashboard')
        }
    }, [jwt]); // don't use getJwt cuase getJwt is a function reference and the reference won't change
    const sendLoginRequest = () => {
        if (password !== passwordRepeat) {
            alert('password not matched!')
            return
        }
        const reqBody = {
            name: name,
            username: username,
            password: password
        }

        fetch(`${apiRoot}/api/auth/register`, {
            "headers": {
                "Content-Type": "application/json"
            },
            "method": "post",
            body: JSON.stringify(reqBody)
        }).then(response => {
            if (response.status === 200) {
                return Promise.all([response.json(), response.headers])
            } else {
                return Promise.reject('Invalid register attempt')
            }
        })
            .then(([data, headers]) => {
                alert('register success!')
                navigate('/login')
            }).catch((message) => { // would catch the Promise.reject
            alert(message)
        })
    }

    const responseMessage = (response: any) => {
        // console.log(response.credential)
        fetch(`${apiRoot}/api/oauth/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (response.credential)
            },
        })
            .then((response) => response.json())
            .then((user: UserDto) => {
                // console.log(`user: ${JSON.stringify(user)}`)
                if (user && user.jwt) {
                    updateJwt(user.jwt)
                } else return Promise.reject('Invalid login attempt')
            })
            .catch((error) => {
                alert(`error occured when send access token: ${error}`);
            });
    }

    const errorMessage = () => {
        alert('google login error')
    }
    return (
        <>
            {/*<div className="container-fluid">*/}
            {/*    <div className="row  justify-content-center align-items-center d-flex-row text-center h-100">*/}
            {/*        <div className="col-12 col-md-12 col-lg-6 h-50 ">*/}
            {/*            <div className="card shadow">*/}
            {/*                <div className="card-body mx-auto">*/}
            {/*                    <h4 className="card-title mt-3 text-center">Create Account</h4>*/}
            {/*                    <p className="text-center">Get started with your free account</p>*/}

            {/*                    <GoogleLogin prompt_parent_id={'googleButton'} size={'large'} shape={'pill'}*/}
            {/*                                 logo_alignment={'center'}*/}
            {/*                                 onSuccess={responseMessage} onError={errorMessage} width={350}/>*/}
            {/*                    <br/>*/}
            {/*                    <p className="text-muted font-weight-bold ">*/}
            {/*                        <span>OR</span>*/}
            {/*                    </p>*/}
            {/*                    <form>*/}
            {/*                        <div className="form-group input-group">*/}
            {/*                            <span className="input-group-text" id="basic-addon1"><i*/}
            {/*                                className="fa-solid fa-lock"></i></span>*/}
            {/*                            <input className={'form-control'} type={'text'} id={'name'}*/}
            {/*                                   name={'name'}*/}
            {/*                                   onChange={(event) => setName(event.target.value)}/>*/}
            {/*                        </div>*/}
            {/*                        <br/>*/}
            {/*                        <div className="form-group input-group">*/}
            {/*                            <span className="input-group-text"*/}
            {/*                                  id="basic-addon1"><i className="fa-solid fa-lock"></i></span>*/}
            {/*                            <input className={'form-control'} type={'email'} id={'username'}*/}
            {/*                                   name={'username'} onChange={(event) => setUsername(event.target.value)}/>*/}
            {/*                        </div>*/}
            {/*                        <br/>*/}
            {/*                        <div className="form-group input-group">*/}
            {/*                            <span className="input-group-text" id="basic-addon1"><i*/}
            {/*                                className="fa-solid fa-lock"></i></span>*/}
            {/*                            <input className={'form-control'} type={'password'} id={'password'}*/}
            {/*                                   name={'password'}*/}
            {/*                                   onChange={(event) => setPassword(event.target.value)}/>*/}
            {/*                        </div>*/}
            {/*                        <br/>*/}
            {/*                        <div className="form-group input-group">*/}
            {/*                            <span className="input-group-text" id="basic-addon1"><i*/}
            {/*                                className="fa-solid fa-lock"></i></span>*/}
            {/*                            <input className={'form-control'} type={'password'} id={'password-repeat'}*/}
            {/*                                   name={'password-repeat'}*/}
            {/*                                   onChange={(event) => setPasswordRepeat(event.target.value)}/>*/}
            {/*                        </div>*/}
            {/*                        <br/>*/}
            {/*                        <div className="form-group">*/}
            {/*                            <button type={'button'} className={'btn btn-primary'}*/}
            {/*                                    onClick={sendLoginRequest}>*/}
            {/*                                Register*/}
            {/*                            </button>*/}
            {/*                        </div>*/}
            {/*                    </form>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="text-center w-full md:w-8/12 lg:w-5/12 rounded-lg shadow-2xl bg-white">
                <div className="">
                    <h4 className="mb-4 text-4xl font-bold leading-none tracking-tight text-gray-900 ">Sign Up</h4>
                    <div className="flex flex-col items-center">
                        <GoogleLogin prompt_parent_id={'googleButton'} size={'large'} shape={'pill'}
                                     logo_alignment={'center'}
                                     onSuccess={responseMessage} onError={errorMessage} width={350}/>
                    </div>
                </div>

                <form className="px-8 pt-6 pb-8 mb-4 border-none">
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faUser} beat/>
                          </span>
                        <input type={'text'} id={'name'}
                               name={'name'}
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="name"
                               onChange={(event) => setName(event.target.value)}/>
                    </div>
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faEnvelope} beat/>
                          </span>
                        <input type="email" id="username" name="username"
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="john_ed@yardsalenearme.com"
                               onChange={(event) => setUsername(event.target.value)}/>
                    </div>
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faLock} beat/>
                          </span>
                        <input type="password" id="password" name="password"
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="********" onChange={(event) => setPassword(event.target.value)}/>
                    </div>
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faLock} beat/>
                          </span>
                        <input type="password" id="password-repeat" name="password-repeat"
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="********" onChange={(event) => setPasswordRepeat(event.target.value)}/>
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button" onClick={sendLoginRequest}>
                            Register
                        </button>
                        <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                           href="/login">
                            already have account?
                        </a>
                    </div>
                </form>
            </div>
        </>
    )
}
export default Register;