import React, {useState, useEffect} from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Check if the user has scrolled down and is on a mobile device
    const toggleVisibility = () => {
        const mobileWidth = 768; // Adjust this for your needs
        if (window.innerWidth <= mobileWidth && window.pageYOffset > 20) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
        // console.log(`is visible: ${isVisible}`)
    };

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        <>
            <button id={'scroll-to-top-btn'} className={'btn btn-primary rounded-circle'} onClick={scrollToTop}
                    style={{position: 'fixed', bottom: '20px', right: '20px'}}
                    hidden={!isVisible}>
                <i className="fa-solid fa-lock"></i>
            </button>
        </>
    );
};

export default ScrollToTopButton;