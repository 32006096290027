import {useState, useEffect} from 'react';

const useJwt = () => {
    const [jwt, setJwt] = useState<string>('');

    // Load JWT from local storage when the hook is used
    useEffect(() => {
        const storedJwt = localStorage.getItem('jwt');
        if (storedJwt) {
            setJwt(storedJwt);
        }
    }, []);

    // Function to update JWT in both state and local storage
    const updateJwt = (newJwt: string) => {
        localStorage.setItem('jwt', newJwt);
        setJwt(newJwt);
    };

    // Function to clear JWT from both state and local storage
    const clearJwt = () => {
        localStorage.removeItem('jwt');
        setJwt('');
    };

    const getJwt = () => {
        const storedJwt = localStorage.getItem('jwt')
        return (storedJwt !== null) ? storedJwt : ''
    }


    return {getJwt, updateJwt, clearJwt};
};

export default useJwt;