// api.js

// Development API root URL
const developmentAPIRoot = 'https://localhost:5000';
// const developmentAPIRoot = 'http://18.220.58.21:8080';
// Production API root URL
// const productionAPIRoot = 'https://o6ijphfgjj.execute-api.us-east-2.amazonaws.com/yardsale_prod';
// const productionAPIRoot = 'https://localhost:8080';
const productionAPIRoot = 'https://j7uxczkx2h.execute-api.us-east-2.amazonaws.com';
// Determine the API root based on the environment (development or production)
const apiRoot = process.env.NODE_ENV === 'production' ? productionAPIRoot : developmentAPIRoot;

export default apiRoot;