import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import apiRoot from "../api";
import {UserDto} from "../model/userDto";
import {GoogleLogin} from "@react-oauth/google";
import useJwt from "../util/useJwt";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons"
import {faLock} from "@fortawesome/free-solid-svg-icons"

const Login = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    // const [jwt, setJwt] = useLocalState('', 'jwt')
    // const [user, setUser] = useLocalState('', 'user')
    const {getJwt, updateJwt, clearJwt} = useJwt();
    const jwt = getJwt()
    const navigate = useNavigate()

    useEffect(() => {
        if (jwt) {
            navigate('/dashboard')
        }
    }, [jwt]);

    const login = (user: UserDto) => {
        updateJwt(user.jwt)
    }
    const sendLoginRequest = () => {
        const reqBody = {
            username: username,
            password: password
        }

        fetch(`${apiRoot}/api/auth/login`, {
            "headers": {
                "Content-Type": "application/json"
            },
            "method": "post",
            body: JSON.stringify(reqBody)
        }).then(response => {
            if (response.status === 200) {
                // console.log(response.headers)
                // return Promise.all([response.json(), response.headers])
                return response.json();
            } else {
                return Promise.reject('Invalid login attempt')
            }
        })
            .then((user: UserDto) => {
                if (user && user.jwt) {
                    login(user);
                } else return Promise.reject('Invalid login attempt')
            }).catch((error) => { // would catch the Promise.reject
            alert(error)
        })
    }

    const responseMessage = (response: any) => {
        // console.log(response.credential)
        fetch(`${apiRoot}/api/oauth/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + (response.credential)
            },
        })
            .then((response) => response.json())
            .then((user: UserDto) => {
                if (user && user.jwt) {
                    login(user);
                } else return Promise.reject('Invalid login attempt')
            })
            .catch((error) => {
                alert(`error occured when send access token: ${error}`);
            });
    }

    const errorMessage = () => {
        alert('google login error')
    }

    return (
        <>
            <div className="text-center w-full md:w-8/12 lg:w-5/12 rounded-lg shadow-2xl bg-white">
                <div className="">
                    <h4 className="mb-4 text-4xl font-bold leading-none tracking-tight text-gray-900 ">Login</h4>
                    <div className="flex flex-col items-center">
                        <GoogleLogin prompt_parent_id={'googleButton'} size={'large'} shape={'pill'}
                                     logo_alignment={'center'}
                                     onSuccess={responseMessage} onError={errorMessage} width={350}/>
                    </div>
                </div>

                <form className="px-8 pt-6 pb-8 mb-4 border-none">
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faEnvelope} beat/>
                          </span>
                        <input type="email" id="username" name="username"
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="john_ed@yardsalenearme.com" onChange={(event) => setUsername(event.target.value)}/>
                    </div>
                    <div className="flex mb-6">
                          <span
                              className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md ">
                              <FontAwesomeIcon icon={faLock} beat/>
                          </span>
                        <input type="password" id="password" name="password"
                               className="shadow rounded-none rounded-e-lg border focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                               placeholder="********" onChange={(event) => setPassword(event.target.value)}/>
                    </div>

                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button" onClick={sendLoginRequest}>
                            Sign In
                        </button>
                        <a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
                           href="/register">
                            already have account?
                        </a>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login;