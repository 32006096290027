import React, {useEffect, useState} from 'react';
import {useLocalState} from "../util/useLocalStorage";
import {useNavigate} from 'react-router-dom';
import apiRoot from "../api";
import useJwt from "../util/useJwt";
import moment from "moment/moment";
import Datepicker from "tailwind-datepicker-react";
// import DatePicker from "react-datepicker";

const EventView = () => {
    // const [jwt, setJwt] = useLocalState('', 'jwt');
    // const [user] = useLocalState('', 'user')
    const {getJwt, updateJwt, clearJwt} = useJwt();
    const jwt = getJwt()
    const [yardsale, setYardsale] = useState({
        id: '',
        address: '',
        date: new Date(),
        description: ''
    });
    const eventId = window.location.href.split('/event/')[1];
    const navigate = useNavigate()

    function updateEvent(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {name, value} = event.target;
        // const name = '';
        // const value = '';
        setYardsale({
            ...yardsale,
            [name]: value
        });
    }

    // console.log(`eventId: ${eventId}`)
    useEffect(() => {
        fetch(`${apiRoot}/api/event/${eventId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            method: 'GET'
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            }
            throw new Error('Response status is not OK');
        }).then((data) => {
            // console.log(moment(data.date, "MM/DD/YYYY").toDate())
            setYardsale({...data, date: moment(data.date, "MM/DD/YYYY").toDate()})
        }).catch((message) => {
            navigate('/dashboard')
        })
    }, [jwt]);

    function save(event: any) {
        event.preventDefault();
        if (!yardsale.address) {
            alert('address is required')
            return
        }
        if (yardsale.date != null && !yardsale.date) {
            alert('date is required')
            return
        }
        fetch(`${apiRoot}/api/event/${eventId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`,
            },
            method: 'PUT',
            body: JSON.stringify({
                id: yardsale.id,
                address: `${yardsale.address}`,
                description: yardsale.description,
                date: moment(yardsale.date).format('L')
            })
        }).then((response) => {
            if (response.status === 200) {
                alert('update success')
            } else throw new Error('there is an error');
        })
            .catch((message) => {
                alert(`update fail: ${message}`)
            })
    }

    const [dateShow, setDateShow] = useState(false)
    const handleChange = (selectedDate: Date) => {
        setYardsale({...yardsale, date: selectedDate})
    }
    const handleClose = (state: boolean) => {
        setDateShow(state)
    }

    const dateOptions = {
        maxDate: new Date("2030-01-01"),
        minDate: new Date(),
        defaultDate: new Date(yardsale.date),
    }
    return (
        <div>
            {yardsale.id ? (
                <>
                    <h4 className="mb-4 text-lg font-bold leading-none tracking-tight text-gray-900 text-center">Event
                        ID: {yardsale.id}</h4>

                    <form className={"max-w-lg mx-auto w-full p-2"}>
                        <div className="mb-2">
                            <label htmlFor="address"
                                   className="block mb-2 text-sm font-medium text-gray-900 e">
                                Address
                            </label>
                            <input type="text"
                                   id="address" name="address"
                                   onChange={updateEvent} defaultValue={yardsale.address}
                                   aria-describedby="helper-text-explanation"
                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                                   placeholder="2585 N Tustin St, Orange, CA 92865"/>
                        </div>
                        <div className="mb-2 z-0">
                            <label htmlFor="address"
                                   className="block mb-2 text-sm font-medium text-gray-900 e">
                                Date
                            </label>
                            <Datepicker options={dateOptions} onChange={handleChange} show={dateShow}
                                        setShow={handleClose}/>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="message"
                                   className="block mb-2 text-sm font-medium text-gray-900 e">
                                Description
                            </label>
                            <textarea id="description" name="description"
                                      onChange={updateEvent} defaultValue={yardsale.description}
                                      rows={4}
                                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                      placeholder="Tell us what you gonna sell..."
                            ></textarea>
                        </div>
                        <button type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                onClick={save}>
                            Create New Yard Sale
                        </button>
                    </form>

                </>
            ) : <></>}
        </div>
    );
}

export default EventView