import React, {useEffect, useState} from "react";

import Map from "./Map";
import apiRoot from "../api";

import {YardsaleEvent} from "../model/yardsaleEvent";
import {useNavigate} from "react-router-dom";
import {MarkerInfo} from "../model/markerInfo";
import useJwt from "../util/useJwt";


const Homepage = () => {
    const navigate = useNavigate()
    const [location, setLocation] = useState<{ lat: number, lng: number } | null>(null);
    const zipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    // Define a state variable to store the input value
    const [zipcode, setZipcode] = useState('');
    const changeZipcode = () => {
        if (!zipcode) return;
        if (zipRegExp.test(zipcode)) {
            fetch(`${apiRoot}/api/address/zipcode?zipcode=${zipcode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'GET'
            }).then(response => {
                if (response.status === 200) return response.json();
            }).then((data: { latitude: number, longitude: number }) => {
                setLocation({lat: data.latitude, lng: data.longitude})
            }).catch(error => {
                alert('zipcode not valid')
            })
        } else {
            alert("zip code is invalid");
            setZipcode('')
        }
    }
    const {getJwt, updateJwt, clearJwt} = useJwt();
    const jwt = getJwt();
    useEffect(() => {
        // console.log(process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID)
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                // Extract latitude and longitude from the position object
                const {latitude, longitude} = position.coords;
                setLocation({lat: latitude, lng: longitude});
            }, (error) => {
                console.error('Error getting location:', error);
                setLocation({lat: 34.052235, lng: -118.243683}) // los angeles
            });
        } else {
            console.error('Geolocation is not available in this browser.');
            setLocation({lat: 34.052235, lng: -118.243683})
        }
    }, []);

    return (
        <>
            <div className="block">
                <div>
                    <p className="text-center">
                        <span className="text-green-700 font-bold">*Green Pin is created by system for test</span>
                        <br/>
                        <span className="text-red-700 font-bold">*Red Pin is created by user</span>
                    </p>
                </div>

                <div className="shadow-2xl m-1">
                    <form className="align-middle">
                        <label htmlFor="search"
                               className="mb-2 text-sm font-medium text-gray-900 sr-only e">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="number" name="zipcode" id="zipcode"
                                   className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                                   placeholder="zipcode"
                                   onChange={(e) => {
                                       setZipcode(e.target.value)
                                   }}
                                   onKeyUp={(e) => {
                                       if (e.key === 'Enter') changeZipcode()
                                   }}
                            />
                            <button type="button"
                                    className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
                                    onClick={changeZipcode}>
                                Search
                            </button>
                        </div>
                    </form>
                    <div className="content-center">
                        {(location) ? <Map center={location!}/> : (<></>)}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Homepage;